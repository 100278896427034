import cloudinary from 'cloudinary-core';
var CDN_ROOT = 'https://d2abh92eaqfsaa.cloudfront.net';
var TO_REPLACE = 'https://res.cloudinary.com/dgps1radv';
var cl = cloudinary.Cloudinary.new({
  cloud_name: 'dgps1radv',
  secure: true
});

function makeCloudinaryUrlForPhoto() {
  var photo = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var imgixGlobalOptions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  // console.log(imgixGlobalOptions)
  photo = photo || {};
  var _photo = photo,
      url = _photo.url,
      alt = _photo.alt,
      type = _photo.type,
      pid = _photo.pid,
      shopId = _photo.s,
      id = _photo.id,
      ext = _photo.e;
  var width = opts.width,
      height = opts.height,
      custom = opts.custom;
  custom = custom || {}; // new gcs + imgix

  if (type === 'g') {
    var params = Object.assign({
      w: width,
      h: height,
      auto: 'format'
    }, imgixGlobalOptions, custom); // console.log(params)

    var qsArray = [];

    for (var key in params) {
      if (params[key]) {
        qsArray.push("".concat(key, "=").concat(params[key]));
      }
    }

    var qstring = '?' + qsArray.join('&');

    var _url = "https://afag.imgix.net/".concat(shopId, "/").concat(id, ".").concat(ext).concat(qstring);

    return _url;
  }

  var clType = 'fetch';

  if (type === 'cl') {
    clType = null;
    url = pid;
  }

  if (!url) {
    return 'no-pic';
  } // if no image is associated with product


  var src = cl.url(url, {
    width: Number(width || 50),
    crop: "fill",
    type: clType
  });

  if (height) {
    src = cl.url(url, {
      height: Number(height || 50),
      crop: "fill",
      type: clType
    });
  }

  if (width && height) {
    src = cl.url(url, {
      height: Number(height || 50),
      width: Number(width || 50),
      crop: "fill",
      type: clType
    });
  }

  return src.replace(TO_REPLACE, CDN_ROOT);
}

export default makeCloudinaryUrlForPhoto;