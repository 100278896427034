import React from "react"

const colors = {
  // shadow: "#6F655C",
  // text: "black",
  dark: "#e6e0c2",
  light: "#272727"
}


function SvgComponent(props) {
  const textColor = colors[props.theme || "light"]

  const color="#9f0d1c"
  return (
    <svg
      className="nav-bar-logo"
      viewBox="0 0 204.6 58.7" {...props}>
     <path
       fill={textColor}
       d="M38.1 39.7C36.5 50.1 31 55.2 22.4 55.2c-11.6 0-18.1-8.7-18.1-25.3 0-16.8 6.4-25.5 18.3-25.5 9.7 0 14.6 6.4 14.9 15.6l-11.2 2.9c-.3-6.6-1.3-9.6-4.3-9.6-3.2 0-5 3.4-5 16.5s2.2 16.5 5.5 16.5c2.7 0 4.8-2.7 5.5-9l10.1 2.4zM72.2 54.5H60.7V31.9c0-3.5-1.1-5-3.4-5-1.2 0-2.6.4-3.9 1.5v26.1H42V4h11.5v18.1c3.3-2.9 5.6-4.2 9.9-4.2 5.7 0 8.8 3.9 8.8 10.4v26.2zM89.3 13.4H77.5V4.2h11.8v9.2zm-.2 41.1H77.6v-36h11.5v36zM124.9 54.5h-11.5V31.9c0-3.5-1.1-5-3.4-5-1.2 0-2.6.4-3.9 1.5v26.1H94.7v-36h10.1l.8 4.2c3.6-3.2 6-4.8 10.5-4.8 5.7 0 8.8 3.9 8.8 10.4v26.2zM158.5 54.5h-10.1l-.7-3.9c-2.9 2.7-5.7 4.3-9.7 4.3-5 0-9-2.9-9-10 0-7.9 7.2-11.6 17.9-12.8v-.9c0-3.6-1.1-4.9-4.3-4.9-2.5 0-5.6 1.1-8.6 2.8l-3.4-7.5c4.3-2.4 8.8-3.8 15.1-3.8 9 0 12.8 2.7 12.8 11.1v25.6zM147 37.8c-4.6.6-7.1 2.2-7.1 5.7 0 3 1.6 4.1 3.4 4.1 1.3 0 2.4-.4 3.6-1v-8.8zM200.5 54.5h-22.6v-7.9h5.7v-30h-5.7v-5.8L189 4.6h6v42.1h5.4v7.8z" />
   </svg>
  )
}

export default SvgComponent
