import React from 'react'
import Toggle from './Toggle'
import sun from './sun.png';
import moon from './moon.png';
import './Toggle.css'

export default function () {
  const theme = window.__preferredTheme
  return(
      <Toggle
          icons={{
            checked: (
              <img
                src={moon}
                width="16"
                height="16"
                role="presentation"
                style={{ pointerEvents: 'none' }}
              />
            ),
            unchecked: (
              <img
                src={sun}
                width="16"
                height="16"
                role="presentation"
                style={{ pointerEvents: 'none' }}
              />
            ),
          }}
          checked={theme === 'dark'} // this.state.theme === 'dark'
          onChange={e =>
            {
              console.log('checked: ', e.target.checked)
              window.__setPreferredTheme(
              e.target.checked ? 'dark' : 'light'
            )}
          }
      />
  )
}
