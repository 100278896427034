import React from "react";
import {Helmet} from "react-helmet";
import {injectShopContainer} from '../react/ShopDataContainer'
import {getRestaurantId} from '../utils'
import AppleStartUpImageHeaders from './AppleStartUpImageHeaders'

class Base extends React.Component {
  render () {

    const shop = this.props.shop
    if (!shop) {return null}
    // console.log('hi', shop)
    let name = shop.name && shop.name.en
    let description = shop.description && shop.description.en
    return (
      <Helmet

        titleTemplate={`%s | ${name}`}
        defaultTitle={name}
      >
          <meta charSet="utf-8" />
          {/* <title>{name}</title> */}
          <meta name="description" content={description} />
          {/* <link rel="canonical" href="shop.canonical" /> */}

          <link rel="manifest" href={`${process.env.PUBLIC_URL}/${getRestaurantId()}.json`} />
          <link rel="apple-touch-icon" sizes="250x250"
            href={`${process.env.PUBLIC_URL}/${getRestaurantId()}.png`} />
          <link rel="apple-touch-icon" sizes="250x250"
            href={`${process.env.PUBLIC_URL}/${getRestaurantId()}.png`} />
          <link rel="shortcut icon"
            href={`${process.env.PUBLIC_URL}/${getRestaurantId()}.png?v=1`}
          />
          <meta name="apple-mobile-web-app-capable" content="yes"/>

          {/* Open Graph */}
          <meta property="og:title" content={name} />
          <meta property="og:site_name" content={name} />
          <meta property="og:description" content={description} />
          {/* <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" /> */}
          {/* <meta property="og:image" content={} /> */}
          {/* <meta property="og:url" content="http://euro-travel-example.com/index.htm" /> */}
      </Helmet>


    );
  }
};

let injectedBase = injectShopContainer(Base)

export {
  injectedBase as Base
}
