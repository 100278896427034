
import * as Components from "./China1"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "china1"
}
