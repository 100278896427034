import React from 'react'
import Photo from '../../react/Photo'
import Price from '../Price'

export default function PizzaToppings (props) {
  const {
    pizzaSections=1,
    modifiers=[],
    editCustomerChoice,
    customerPicks
  } = props

  let sections = [{
    label: "whole pizza"
  }]
  if (pizzaSections === 2) {
    sections = [
      // { label: 'first half'   },
      { sectionId: 1, label: 'first half'   },
      { sectionId: 2, label: 'second half'  }
    ]
  }

  // let options = {}
  // modifiers.forEach(({id}) => options[id] = false)
  return (<div className="product-modifiers">
    {sections.map((section) => {
      const {label, sectionId} = section
      return (
      <fieldset>
        <legend>{label}</legend>

        {modifiers.map(modifier => {
          let selection = customerPicks[modifier.id] || {}
          let picked = selection[sectionId]
          let className = picked ? 'picked' : ''
          const pickObj   = Object.assign({}, selection, { [sectionId]: true  })
          const unPickObj = Object.assign({}, selection, { [sectionId]: false })
          return(
            <label htmlFor={modifier.id + "__" + sectionId} className={"modifier " + className} key={modifier.id}>
              {modifier.url ? <Photo url={modifier.url} width={80} height={80} /> : null}
              {modifier.name || modifier.id}
              <Price modifier={modifier} priceDiv={2} customerPicks={customerPicks}></Price>
              <input id={modifier.id + "__" + sectionId} type="checkbox"
              checked={picked}
              onChange={(event) => editCustomerChoice(
                {[modifier.id]: event.target.checked ? pickObj : unPickObj}
              )}
              />
            </label>
          )})}
      </fieldset>
      )
    })}
  </div>)
}
