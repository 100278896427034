import React, { Component, useState } from 'react';
import {injectCartContainer} from '../react/CartContainer'

import {round} from 'lodash'
import './CheckoutTips.scss'

const PERCENTAGES = [
  10, 15, 20
]



function CheckoutTips ({ preTipTotalWithTax, cartType, cart, shopId }) {
  const usePercentageTipping = (preTipTotalWithTax > 10)
  const tipOptions = usePercentageTipping ?
    PERCENTAGES.map((number) => {
      return({
        display: `${number}%`,
        tipAmount: Math.round(preTipTotalWithTax * number)
      })
    }) :
    [1,2,3,4].map((dollar) => ({ display: `$${dollar}`, tipAmount: dollar * 100 }))
  function handleSelectTip (tipAmount) {
    console.log('selected: ', tipAmount)
    cart.setState({preTipPrimaryCents: tipAmount})
  }
  function handlePickCustomTip () {
    console.log('custom tip')
    const inputString = window.prompt("Enter Tip:", '') || ''
    const digitsOnlyString = inputString.replace(/[^0-9\.]/g, '')
    const tipDollars = parseFloat(digitsOnlyString)
    if (tipDollars || (tipDollars === 0)) {
      cart.setState({preTipPrimaryCents: tipDollars * 100})
    } else {
      console.log('no digits entered')
    }
  }
  const selectTipAmountInCents = cart.state.preTipPrimaryCents
  // determine if need to display custom-entered tip (or a tip that someone doesn't exist)
  let isCustomTip = false
  let tipLater = false
  if (parseInt(selectTipAmountInCents)) {
    isCustomTip = true
  }
  tipOptions.forEach(({tipAmount}) => {
    if (tipAmount === selectTipAmountInCents) {
      isCustomTip = false
    }
  })
  if (parseInt(selectTipAmountInCents) === 0) {
    tipLater = true
  }

  let tipText = (cartType === 'DELIVERY') ?
  "100% goes to driver, thank you!" :
  "100% goes to staff, thank you!"

  if ((shopId === 'cravesushi')&&(cartType === 'PICKUP')) {
    tipText = ''
  }

  return(
    <section className="checkout-tips-section">
      <fieldset>
        <h2 className="instruction">Add a tip:</h2>
        <p className="hint">{tipText}</p>
        <div className="all-tip-options">
          <div className="options-container primary">
            {tipOptions.map(({display, tipAmount}, index) => {
              const id = tipAmount
              // console.log(tipAmount, selectTipAmountInCents)
              const selectedOptionClass = (selectTipAmountInCents === (tipAmount)) ?
                " selected" : ""
              return(
                <div
                  key={id}
                  className={"tip-option-clickable" + selectedOptionClass}>
                  <input
                    onChange={handleSelectTip.bind(this, tipAmount)}
                    name="primary-tip" type="radio" id={id} value={tipAmount} />
                  <label htmlFor={id}>
                    <span className="content">{display}</span>
                    {usePercentageTipping && <span className="actualAmount">
                      ${tipAmount / 100}
                    </span>}
                  </label>
                </div>
              )
            })}
          </div>



          <div className="options-container custom">
            <div className={"tip-option-clickable custom" + (isCustomTip ? (' selected') : "")}>
              <input
                onClick={handlePickCustomTip}
                name="primary-tip" type="radio" id="custom" value={"CUSTOM"} />
              <label htmlFor="custom">
                {!isCustomTip ?
                  <span className="content">custom</span> :
                  <span className="content">
                    ${selectTipAmountInCents / 100}
                    <span className="edit-label">edit</span>
                  </span>
                }
              </label>
            </div>
          </div>

          <div className="options-container later">
            <div className={"tip-option-clickable custom" + (tipLater ? (' selected') : "")}>
              <input
                onClick={handleSelectTip.bind(this, 0)}
                name="primary-tip" type="radio" id="tip-later" value={"tip-later"} />
              <label htmlFor="tip-later">
                <span className="content">
                  not now
                </span>
              </label>
            </div>
          </div>


        </div>
      </fieldset>
    </section>
  )
}

export default injectCartContainer(CheckoutTips)
