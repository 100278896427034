// import hostnameMap from './hostnameToID'
import {getShopId} from '../Custom/getCustomComponent'

export function getRestaurantId() {
  return getShopId()

  // temp, use my fish stop - for react-snap generation
  // if (process.env.REACT_APP_SHOP_ID) {
  //   return process.env.REACT_APP_SHOP_ID
  // }
  //
  // let hostname = window.location.hostname
  //
  // let elements = hostname.split('.')
  // let [restId, domain, tld] = elements
  // if (elements.length === 3 && (domain === 'afoodapart' || domain === 'example') && tld === 'com') {
  //   return restId
  // }

  // return hostnameMap[hostname] || "georges"
}
