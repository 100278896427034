import React from 'react'
import Photo from '../../react/Photo'
import Price from '../Price'
import makeCloudinaryUrlForPhoto from 'afa-photo'
import './Buffet.scss'

// customerPicks =

const Buffet = ({modifiers=[], editCustomerChoice, customerPicks}) => {
  let options = {}
  modifiers.forEach(({id}) => options[id] = false)
  return (<div className="buffet product-modifiers">
    {modifiers.map(modifier => {
      return(
        <Modifier
          key={modifier.id}
          modifier={modifier}
          customerPicks={customerPicks}
          editCustomerChoice={editCustomerChoice}
        />
      )
      })}
  </div>)
}

function Modifier ({modifier, customerPicks, editCustomerChoice}) {
  let picked = customerPicks[modifier.id] || false
  let pickedClassName = (picked && picked.quantity) ? 'picked' : ''
  let quantity = picked.quantity || 0
  let photoURL
  if (modifier.photo) {
    photoURL = makeCloudinaryUrlForPhoto(modifier.photo, {width: 110})
  }
  const decrement = () => {
    setQuantity(Number(quantity) - 1)
  }
  const increment = () => {
    setQuantity(Number(quantity) + 1)
  }
  const onQuantityInputChange = (event) => {
    setQuantity(event.target.value)
  }
  const setQuantity = (quantity) => {
    quantity = Number(quantity)
    if (quantity < 1) { quantity = 0}
    let newQuantity = (Number(String(quantity).replace(/[^0-9]/g, '')) || 0)
    editCustomerChoice({[modifier.id]: { quantity: Number(newQuantity)}})
  }
  return(
    <div className={"buffet-mod modifier " + pickedClassName}>
      <div className="quantity panel">
        <div className="content">
          {photoURL ? <div className="buffet-photo-container">
            <img src={photoURL} width={80} alt="" />
          </div> : null}
          <h2
            className="modifier-info-group"
            >

            <label
              // aria-label={`Select ${modifier.name} Quantity. ${modifier.price} each`}
              htmlFor={quantity + modifier.id} className="section-title">


              <span
                aria-label={`Select quantity for ${modifier.name}, ${modifier.price} each.`}
                role="text">
                {/* <span className="thin">quantity for</span> <br/> */}
                {modifier.name}
                {modifier.price && <span className="price"> ${modifier.price}</span> }
                <br/>

              </span>
              {modifier.description && <span className="modifier-description">
                {modifier.description}
              </span>}

            </label>
          </h2>
        </div>
        <div className="control-group">
          <button className="quantity-button" aria-label={"minus one quantity"} onClick={decrement}>−</button>
          <input  className="quantity-input" id={quantity + modifier.id}
            type="number"
            onChange={onQuantityInputChange}
            value={Number(quantity) || 0}/>
          <button className="quantity-button" aria-label={"plus one quantity"} onClick={increment}>+</button>
        </div>
      </div>

      {/* <input id={modifier.id} type="checkbox"
        checked={picked}
        onChange={(event) => editCustomerChoice({[modifier.id]: event.target.checked})}
      />

      <label className={pickedClassName} htmlFor={modifier.id} key={modifier.id}>
        <span className="text" role="text">
          {modifier.url ? <div className="photo-container">
            <Photo url={modifier.url} width={80} height={80} alt="" />
          </div> : null}
          <span className="modifier-name">{modifier.name || modifier.id}</span>
          <Price modifier={modifier} customerPicks={customerPicks}></Price>
        </span>

      </label> */}



    </div>
)
}


export default Buffet
