function getModifierPrice(_ref) {
  var price = _ref.price,
      priceIf = _ref.priceIf;
  var priceDisplay;

  if (Array.isArray(priceIf)) {
    var result = priceIf.find(function (_ref2) {
      var match = _ref2.match;
      return customerPicks[match];
    });
    priceDisplay = result && result.price || 0;
  } else {
    priceDisplay = price || 0;
  }

  return priceDisplay;
}

function getModifierSummary(_ref3) {
  var modifiers = _ref3.modifiers,
      customerPicks = _ref3.customerPicks;
  var totalModifierPrice = 0;
  var totalModifierCount = 0;
  modifiers.forEach(function (mod) {
    var picked = customerPicks[mod.id];

    if (picked) {
      var quantity = isNaN(Number(picked.quantity)) ? 1 : picked.quantity;
      var pMPrice = getModifierPrice(mod) * quantity;
      totalModifierPrice += pMPrice;
      totalModifierCount += quantity;
    }
  });
  return {
    totalModifierCount,
    totalModifierPrice
  };
}

export function validateCustomerPicksForProduct(product) {
  // const {customerPicks={}, modgroups=[]} = product
  var customerPicks = product.customerPicks || {};
  var modgroups = product.modgroups || [];
  var resultsMap = {};
  var resultsArray = [];
  modgroups.forEach(function (_ref4) {
    var modgroupId = _ref4.id,
        required = _ref4.required,
        minCount = _ref4.minCount,
        minPrice = _ref4.minPrice,
        maxCount = _ref4.maxCount,
        modifiers = _ref4.modifiers,
        type = _ref4.type;

    if (!modifiers || modifiers.length === 0) {
      return;
    } // need to contain modifiers


    if (required) {
      var pickedModifier = modifiers.find(function (_ref5) {
        var modifierId = _ref5.id;
        return customerPicks[modifierId];
      });

      if (!pickedModifier) {
        // customer did not make a requried selection
        var errorObj = {
          modgroupId,
          message: 'This selection is required'
        };
        resultsMap[modgroupId] = errorObj;
        resultsArray.push(errorObj);
      }
    }

    if (minCount) {
      var _getModifierSummary = getModifierSummary({
        modifiers,
        customerPicks,
        type
      }),
          totalModifierCount = _getModifierSummary.totalModifierCount,
          totalModifierPrice = _getModifierSummary.totalModifierPrice;

      console.log({
        totalModifierCount,
        totalModifierPrice
      });

      if (totalModifierCount < minCount) {
        var _errorObj = {
          modgroupId,
          message: "Minimum Selection: ".concat(minCount)
        };
        resultsMap[modgroupId] = _errorObj;
        resultsArray.push(_errorObj);
      }
    }

    if (maxCount) {
      var _getModifierSummary2 = getModifierSummary({
        modifiers,
        customerPicks,
        type
      }),
          _totalModifierCount = _getModifierSummary2.totalModifierCount,
          _totalModifierPrice = _getModifierSummary2.totalModifierPrice;

      console.log({
        totalModifierCount: _totalModifierCount,
        totalModifierPrice: _totalModifierPrice
      });

      if (_totalModifierCount > maxCount) {
        var _errorObj2 = {
          modgroupId,
          message: "Maximum Selection: ".concat(maxCount)
        };
        resultsMap[modgroupId] = _errorObj2;
        resultsArray.push(_errorObj2);
      }
    }

    if (minPrice) {
      var _getModifierSummary3 = getModifierSummary({
        modifiers,
        customerPicks,
        type
      }),
          _totalModifierCount2 = _getModifierSummary3.totalModifierCount,
          _totalModifierPrice2 = _getModifierSummary3.totalModifierPrice;

      if (Number(_totalModifierPrice2) < Number(minPrice)) {
        var _errorObj3 = {
          modgroupId,
          message: "Minimum Price: $".concat(min)
        };
        resultsMap[modgroupId] = _errorObj3;
        resultsArray.push(_errorObj3);
      }
    }
  });
  return {
    resultsMap,
    resultsArray,
    validated: resultsArray.length === 0
  }; // check if
}