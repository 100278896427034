

function getRoot () {
  if (process.env.REACT_APP_ENDPOINT_ROOT) {
    return process.env.REACT_APP_ENDPOINT_ROOT
  }
  if (window.location.hostname !== 'localhost') {
    return "https://p39pffu1q4.execute-api.us-west-1.amazonaws.com/v18-sushi-mods"
  }
  if (process.env.NODE_ENV === 'development') {
    return "http://localhost:3010"
  }
  return "https://p39pffu1q4.execute-api.us-west-1.amazonaws.com/v18-sushi-mods"
}

export default function getEndpoint (endpointPath) {
  return [getRoot(), endpointPath].join('/')
}
