/*
product = {
name:
total:
modgroups: [{ name: 'addons', price: _, modifiers: [] }]
}
*/

const calcItemPriceArray = (product, defaultTaxPercentage) => {

  let customerPicks = product.customerPicks || {}

  function getModifierPrice ({price, priceIf}) {
    let priceDisplay
    if (Array.isArray(priceIf)) {
      let result = priceIf.find(({match}) => customerPicks[match])
      priceDisplay = result && Number(result.price) || 0
    } else {
      priceDisplay = Number(price) || 0
    }
    return priceDisplay
  }

  let totalPrice = Number(product.price) || 0

  let modgroups = product.modgroups || []
  let pickedModgroups = []
  try {
    pickedModgroups = modgroups.map(({name, modifiers, type, pizzaSections}) => {
      // if (pizzaSections) {
      //   type = "pizza_toppings"
      // }
      switch (type) {
        case 'single':
          let pickedModifier = modifiers.find(mod => customerPicks[mod.id])
          if (!pickedModifier) {
            return { name, modifiers: []}
          }
          let pMPrice = pickedModifier ? getModifierPrice(pickedModifier) : 0
          totalPrice += pMPrice
          return {
            name, modifiers: [{ name: pickedModifier.name, price: pMPrice }]
          }
          break;
        case 'buffet': {
          let pickedModifiers = []
          modifiers.forEach(mod => {
            let picked = customerPicks[mod.id]
            if (picked && picked.quantity) {
              let quantity = picked.quantity || 0
              let pMPrice = getModifierPrice(mod) * quantity
              totalPrice += pMPrice

              pickedModifiers.push({name: `${quantity} x ${mod.name}`, price: pMPrice, quantity: quantity})
            }
          })
          return {
            name, modifiers: pickedModifiers
          }
        }
        case 'pizza_toppings': {
          let pickedModifiers = []
          modifiers.forEach(mod => {
            let selection = customerPicks[mod.id] || {}
            let numOfSections = Object.values(selection)
              .filter((selected) => (selected === true)).length
            // console.log(numOfSections)
            if (selection) {
              let pMPrice = getModifierPrice(mod) / 2 * numOfSections
              totalPrice += pMPrice
              pickedModifiers.push({name: mod.name, price: pMPrice})
            }
          })
          // add mods as 2 modifier group
          return
        }


        default: // 'multiple choice'
          let pickedModifiers = []
          modifiers.forEach(mod => {
            let picked = customerPicks[mod.id]
            if (picked) {
              let pMPrice = getModifierPrice(mod)
              totalPrice += pMPrice

              pickedModifiers.push({name: mod.name, price: pMPrice})
            }
          })
          return {
            name, modifiers: pickedModifiers
          }

      }
    })
  } catch(e) {
    console.log(e)
    try {window.Sentry.captureException(e)} catch (e) {}
  }
  totalPrice = totalPrice * (Number(product.quantity) || 1)
  // let tags = product.tags || ''
  let taxPercentage = (product.taxP) || defaultTaxPercentage || 0
  if (product && product.sub && product.sub.indexOf('RETAIL') !== -1) {
    taxPercentage = 0
  }
  const productTax = totalPrice * (taxPercentage || 0)
  return {totalPrice, pickedModgroups, productTax}
}

export default calcItemPriceArray
