import React from 'react'
import {Link} from 'react-router-dom'
import {injectShopContainer} from './ShopDataContainer'


class Footer extends React.Component {
  static defaultProps = {

  };

  state = {

  };

  componentWillMount() {

  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }

  render() {
    if (!this.props.shop) {return null}
    let {
      name, phone,
      address_line_1, address_line_2, city, state, zip
    } = this.props.shop

    return (
      <footer className="footer">
        {/*<p>Use this menu in the restaurant on your phone 📲</p>*/}
        <section className="content">
        <p>{name && name.en}</p>

        {city &&
          <div className="address">
            {address_line_1 && <div>{address_line_1}</div>}
            {address_line_2 && <div>{address_line_2}</div>}
            <div>{city}, {state} {zip}</div>
          </div>}

          {phone && <div className="phone">
            ☎ <a className="phone-link" href={`tel:${phone}`}>{phone}</a>
          </div>}
        </section>
      </footer>
    )
  }
}

export default injectShopContainer(Footer)
